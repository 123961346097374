import type { ErrorObject } from '@vuelidate/core'
import { hashString } from '@/utils/hash-string'
import { getCookie } from '@/utils/helpers'

import type { FormState } from '@/types'
declare global {
  interface Window {
    dataLayer: any
  }
}

const useGtm = (state: FormState, errors: ErrorObject[]) => {
  const mainStore = useMainStore()
  const formState = ref(0)

  const userId = (field: string) => hashString(field)
  const companyId = (field: string) => hashString(field.replaceAll(/.+@/g, ''))

  const gtmSuccess = ({
    eventName = 'Resource',
    consent,
  }: {
    eventName?: string | undefined
    consent: boolean
  }): void => {
    if (!window?.dataLayer) return

    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: `${eventName}`,
      eventAction: `${eventName} form: Success`,
      eventLabel: `${eventName} name: ${mainStore.storyData.name}`,
      extraLabel: `Marketing agreement: ${consent}`,
      eventValue: 1,
      nonInteraction: true,
      dimension1: userId(state.email!),
      dimension3: 'Lead',
      dimension6: companyId(state.email!),
      dimension14: true,
    })
    formState.value = 0
  }
  const gtmError = (eventName = 'Resource') => {
    if (!window?.dataLayer) return
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: `${eventName}`,
      eventAction: `${eventName} form: Not validated`,
      eventLabel: `${eventName} name: ${mainStore.storyData.name}`,
      extraLabel: `Not validated form: ${errors.map(
        (error: any) => error.$property,
      )}`,
      eventValue: 0,
      nonInteraction: true,
    })
  }
  const gtmFirstTouch = (eventName = 'Resource') => {
    // these GTM event needs to be pushed only once per page
    watch(
      () => state,
      (_state, _prevState) => {
        if (formState.value === 0) {
          window.dataLayer.push({
            event: 'gaEvent',
            eventCategory: `${eventName}`,
            eventAction: `${eventName} form: Start`,
            eventLabel: `Form name: ${mainStore.storyData.name}`,
            eventValue: 0,
            nonInteraction: true,
          })
        }
        formState.value = 1
      },
    )
  }
  const gtmNewsletterSign = (eventName = 'Sign') => {
    if (!window?.dataLayer) return
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'Newsletter',
      eventAction: `Newletter action:${eventName}`,
      eventLabel: `Newletter action:${eventName}`,
      eventValue: 0,
      nonInteraction: true,
      dimension1: userId(state.email!),
      dimension6: companyId(state.email!),
      dimension12: true,
    })
  }

  const gtmCompanyTypeData = () => {
    return {
      v: 1,
      t: 'event',
      tid: 'UA-33090446-35',
      cid: getCookie('_ga')?.slice(6, -1),
      cd2: getCookie('_ga')?.slice(6, -1),
      uid: userId(state.email!),
      ec: 'Hubspot',
      ea: `Company%20type%3A%20${state.industry}`,
      el: companyId(state.email!),
      cd1: userId(state.email!),
      cd6: companyId(state.email!),
      cd7: state.industry,
      ds: 'hubspot',
    }
  }

  const gtmLeadProcessData = () => {
    return {
      v: 1,
      t: 'event',
      tid: 'UA-33090446-35',
      cid: getCookie('_ga')?.slice(6, -1),
      cd2: getCookie('_ga')?.slice(6, -1),
      uid: userId(state.email!),
      ec: 'Lead',
      ea: 'Lead%20process%3A%20Lead',
      el: companyId(state.email!),
      cd1: userId(state.email!),
      cd6: companyId(state.email!),
      cd13: 'Lead',
      ds: 'hubspot',
    }
  }

  return {
    gtmSuccess,
    gtmError,
    gtmFirstTouch,
    gtmCompanyTypeData,
    gtmLeadProcessData,
    userId,
    companyId,
    gtmNewsletterSign,
  }
}
export default useGtm
